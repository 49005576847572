import Api from "./Api";

export default class Api3dvue extends Api{

    constructor(){
        super();
        let me=this;
    }


    /**
     * Pour récupérer un model
     * @param {String} name
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    getModelByName(name,cbSuccess,cbError){
        this._call(
            "3dvue","get-model",
            {
                name:name,
            },
            cbSuccess,
            cbError
        );
    }


    getProductURL(data, cbSuccess, cbError){
        this._call(
            "3dvue","get-product-url",
            {
                data:data
            },
            cbSuccess,
            cbError
        );
    }

    /**
     * Pour récupérer un model
     * @param {String} name
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    getModelData(name,cbSuccess,cbError){
        this._call(
            "3dvue","get-model-data",
            {
                name:name,
            },
            cbSuccess,
            cbError
        );
    }


    /**
     * Pour mettre à jour un model
     * @param {Object} data
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    updateModel(data,cbSuccess,cbError){
        this._call(
            "3dvue","update-model",
            data,
            cbSuccess,
            cbError
        );
    }

    /**
     * Pour mettre à jour les modèles de la DB
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    updateModelDB(cbSuccess,cbError)
    {
        this._call(
            "3dvue","update-model-db",
            null,
            cbSuccess,
            cbError
        );
    }

    /**
     * Pour récupérer une liste de material
     * @param {Object} materialList
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    getMaterialList(materialList,cbSuccess,cbError){
        this._call(
            "3dvue","get-materialList",
            {
                materialList:JSON.stringify(materialList),
            },
            cbSuccess,
            cbError
        );
    }


    /**
     * Pour mettre à jour un material
     * @param {Object} data
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    updateMaterial(data,cbSuccess,cbError){
        this._call(
            "3dvue","update-material",
            data,
            cbSuccess,
            cbError
        );
    }

    /**
     * Pour mettre à jour les modèles de la DB
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    updateMaterialDB(cbSuccess,cbError)
    {
        this._call(
            "3dvue","update-material-db",
            null,
            cbSuccess,
            cbError
        );
    }


    /**
     * Pour mettre à jour la config
     * @param {Object} data
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    updateConfig(data,cbSuccess,cbError){
        this._call(
            "3dvue","update-config",
            data,
            cbSuccess,
            cbError
        );
    }


    /**
     * Pour saver une image base64 en thumbnail du material
     * @param {String} materialName
     * @param {String} base64
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    saveBase64asThumbnailMaterial(materialName, base64,cbSuccess,cbError){
        this._call(
            "3dvue","save-base64asthumbnail-material",
            {
                materialName:materialName,
                base64image:base64
            },
            cbSuccess,
            cbError
        );
    }


    /**
     * Pour saver une image base64 en thumbnail du model
     * @param {String} modelName
     * @param {String} base64
     * @param {function} cbSuccess
     * @param {function} cbError
     */
    saveBase64asThumbnailModel(modelName, base64,cbSuccess,cbError){
        this._call(
            "3dvue","save-base64asthumbnail-model",
            {
                modelName:modelName,
                base64image:base64
            },
            cbSuccess,
            cbError
        );
    }

}
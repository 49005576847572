import * as THREE from "three";
import Vue3D from "../Vue3D";
import {EventEmitter} from "events";

export default class Vue3DConfiguratorMaterial extends EventEmitter {

    vue3d = null;
    configurator = null;
    folder = null;

    materialCtrl = null;
    opacityCtrl = null;
    sheenCtrl = null;
    sheenRoughnessCtrl = null;
    sheenColorCtrl = null;
    transmissionCtrl = null;
    iorCtrl = null;
    reflectivityCtrl = null;
    thicknessCtrl = null;
    envMapIntensityCtrl = null;
    clearCoatCtrl = null;
    clearCoatRoughnessCtrl = null;
    saveTimeout = null;

    api = {
        mesh:"",
        material:"",
        opacity:1,
        sheen:0,
        sheenRoughness:1,
        sheenColor:0x000000,
        transmission:0,
        ior:1.5,
        reflectivity:0.5,
        thickness:0,
        envMapIntensity:1,
        clearCoat:0,
        clearCoatRoughness:0
    }


    constructor(_configurator)
    {
        super();

        let me = this;
        me.vue3d = _configurator.vue3d;
        me.configurator = _configurator;
    }

    listMesh()
    {
        let me = this;
        me.api.mesh = "";

        let mesh = [];
        me.vue3d.model.traverse(function (child) {
            if (child.isMesh) {
                let pattern = "mesh_";
                if(!child.name.includes(pattern)) return;
                let identifier = pattern+child.name.slice(pattern.length, pattern.length+3);

                if(mesh.includes(identifier)) return;
                mesh.push(identifier);
            }
        });

        mesh.sort();

        me.api.mesh = mesh[0];
        me.api.material = "";

        me.folder = me.configurator.gui.addFolder( 'Materials' );
        me.folder.add( this.api, 'mesh' ).options( mesh ).onChange( function (selection) {
            me.api.material = "";
            if(me.materialCtrl) me.materialCtrl.updateDisplay();

            me.vue3d.model.traverse(function (child) {
                if(child.isMesh) {
                    if(child.name.includes(me.api.mesh))
                    {
                        if(me.materialCtrl) {
                            me.api.material = child.material.name;
                            me.api.opacity = (child.material.opacity)?child.material.opacity:1;
                            me.api.sheen = (child.material.sheen)?child.material.sheen:0;
                            me.api.sheenRoughness = (child.material.sheenRoughness)?child.material.sheenRoughness:1;
                            let color = new THREE.Color(child.material.sheenColor);
                            me.api.sheenColor = (child.material.sheenColor)?color.getHex():0x000000;
                            me.api.transmission = (child.material.transmission)?child.material.transmission:0;
                            me.api.ior = (child.material.ior)?child.material.ior:1.5;
                            me.api.reflectivity = (child.material.reflectivity)?child.material.reflectivity:0.5;
                            me.api.thickness = (child.material.thickness)?child.material.thickness:0;
                            me.api.envMapIntensity = (child.material.envMapIntensity)?child.material.envMapIntensity:1;
                            me.api.clearCoat = (child.material.clearCoat)?child.material.clearCoat:0;
                            me.api.clearCoatRoughness = (child.material.clearCoatRoughness)?child.material.clearCoatRoughness:0;


                            if(me.materialCtrl) me.materialCtrl.updateDisplay();
                            if(me.opacityCtrl) me.opacityCtrl.updateDisplay();
                            if(me.sheenCtrl) me.sheenCtrl.updateDisplay();
                            if(me.sheenRoughnessCtrl) me.sheenRoughnessCtrl.updateDisplay();
                            if(me.sheenColorCtrl) me.sheenColorCtrl.updateDisplay();
                            if(me.transmissionCtrl) me.transmissionCtrl.updateDisplay();
                            if(me.iorCtrl) me.iorCtrl.updateDisplay();
                            if(me.reflectivityCtrl) me.reflectivityCtrl.updateDisplay();
                            if(me.thicknessCtrl) me.thicknessCtrl.updateDisplay();
                            if(me.envMapIntensityCtrl) me.envMapIntensityCtrl.updateDisplay();
                            if(me.clearCoatCtrl) me.clearCoatCtrl.updateDisplay();
                            if(me.clearCoatRoughnessCtrl) me.clearCoatRoughnessCtrl.updateDisplay();
                        }
                    }
                }
            });
        });

        me.listMaterial();
        me.opacity();
        me.sheenCtrl = me.simpleSlider("sheen");
        me.sheenRoughnessCtrl = me.simpleSlider("sheenRoughness");
        me.sheenColorCtrl = me.sheenColor();
        me.transmissionCtrl = me.simpleSlider("transmission");
        me.iorCtrl = me.simpleSlider('ior', 0, 2.333, 0.01);
        me.reflectivityCtrl = me.simpleSlider('reflectivity');
        me.thicknessCtrl = me.simpleSlider('thickness');
        me. envMapIntensityCtrl = me.simpleSlider('envMapIntensity', 0 , 2);
        me.clearCoatCtrl = me.simpleSlider('clearCoat');
        me.clearCoatRoughnessCtrl = me.simpleSlider('clearCoatRoughness');
    }


    listMaterial()
    {
        let me = this;

        me.materialCtrl = me.folder.add( me.api, 'material' ).options( LayoutVars.materials.map(a => a.name) );

        me.materialCtrl.onChange( function (value) {

            if(me.api.mesh !== "" && me.api.material !== "")
            {
                let key = me.api.mesh;
                const materialInfo = LayoutVars.materials.find(({ name }) => name === me.api.material);
                let data = {[key] : materialInfo};

                me.api.opacity = (data[key].opacity)?data[key].opacity:1;
                me.api.sheen = (data[key].sheen)?data[key].sheen:0;
                me.api.sheenRoughness = (data[key].sheenRoughness)?data[key].sheenRoughness:1;
                let color = new THREE.Color(data[key].sheenColor);
                me.api.sheenColor = (data[key].sheenColor)?color.getHex():0x000000;
                me.api.transmission = (data[key].transmission)?data[key].transmission:0;
                me.api.ior = (data[key].ior)?data[key].ior:1.5;
                me.api.reflectivity = (data[key].reflectivity)?data[key].reflectivity:0.5;
                me.api.thickness = (data[key].thickness)?data[key].thickness:0;
                me.api.envMapIntensity = (data[key].envMapIntensity)?data[key].envMapIntensity:1;
                me.api.clearCoat = (data[key].clearCoat)?data[key].clearCoat:0;
                me.api.clearCoatRoughness = (data[key].clearCoatRoughness)?data[key].clearCoatRoughness:0;

                if(me.opacityCtrl) me.opacityCtrl.updateDisplay();
                if(me.sheenCtrl) me.sheenCtrl.updateDisplay();
                if(me.sheenRoughnessCtrl) me.sheenRoughnessCtrl.updateDisplay();
                if(me.sheenColorCtrl) me.sheenColorCtrl.updateDisplay();
                if(me.transmissionCtrl) me.transmissionCtrl.updateDisplay();
                if(me.iorCtrl) me.iorCtrl.updateDisplay();
                if(me.reflectivityCtrl) me.reflectivityCtrl.updateDisplay();
                if(me.thicknessCtrl) me.thicknessCtrl.updateDisplay();
                if(me.envMapIntensityCtrl) me.envMapIntensityCtrl.updateDisplay();
                if(me.clearCoatCtrl) me.clearCoatCtrl.updateDisplay();
                if(me.clearCoatRoughnessCtrl) me.clearCoatRoughnessCtrl.updateDisplay();

                me.vue3d.setMaterials(data);

                me.configurator.modelConfigurator.updateURLFromScene();
            }
        });
    }

    destroy()
    {
        if(this.folder) this.folder.destroy();
    }

    save()
    {
        let me = this;

        if(me.api.mesh !== "" && me.api.material !== "")
        {
            let color = new THREE.Color(me.api.sheenColor);
            let data = {
                materialName : me.api.material,
                opacity : me.api.opacity,
                sheen : me.api.sheen,
                sheenRoughness : me.api.sheenRoughness,
                sheenColor : "#"+color.getHexString(),
                transmission : me.api.transmission,
                ior : me.api.ior,
                reflectivity : me.api.reflectivity,
                thickness : me.api.thickness,
                envMapIntensity : me.api.envMapIntensity,
                clearCoat : me.api.clearCoat,
                clearCoatRoughness : me.api.clearCoatRoughness,
            }

            me.emit(Vue3D.CONFIGURATOR_UPDATE_MATERIAL, data);

            const materialInfo = LayoutVars.materials.find(({ name }) => name === me.api.material);
            materialInfo.opacity = me.api.opacity;
            materialInfo.sheen = me.api.sheen;
            materialInfo.sheenRoughness = me.api.sheenRoughness;
            materialInfo.sheenColor = "#"+color.getHexString();
            materialInfo.transmission = me.api.transmission;
            materialInfo.ior = me.api.ior;
            materialInfo.reflectivity = me.api.reflectivity;
            materialInfo.thickness = me.api.thickness;
            materialInfo.envMapIntensity = me.api.envMapIntensity;
            materialInfo.clearCoat = me.api.clearCoat;
            materialInfo.clearCoatRoughness = me.api.clearCoatRoughness;

        }
    }


    simpleSlider(parameter, min = 0, max = 1, step = 0.01)
    {
        let me = this;

        return me.folder.add(me.api, parameter, min, max, step).onChange((value)=>{
            me.vue3d.model.traverse(function (child) {
                if (child.isMesh && child.name.includes(me.api.mesh)) {
                    child.material[parameter] = value;
                    child.material.needsUpdate = true;
                }
            });

            if(me.saveTimeout) clearTimeout(me.saveTimeout);
            me.saveTimeout = setTimeout(me.save.bind(me), 500);
        });
    }


    opacity()
    {
        let me = this;

        me.opacityCtrl = me.folder.add(me.api, 'opacity', 0, 1, 0.01).onChange((value)=>{
            me.vue3d.model.traverse(function (child) {
                if (child.isMesh && child.name.includes(me.api.mesh)) {
                    child.material.transparent = true;
                    child.material.opacity = value;
                    child.material.side = THREE.DoubleSide;
                    child.material.needsUpdate = true;
                }
            });

            if(me.saveTimeout) clearTimeout(me.saveTimeout);
            me.saveTimeout = setTimeout(me.save.bind(me), 500);
        });
    }

    sheenColor()
    {
        let me = this;

        me.sheenColorCtrl = me.folder.addColor(me.api, 'sheenColor').onChange((value)=>{
            me.vue3d.model.traverse(function (child) {
                if (child.isMesh && child.name.includes(me.api.mesh)) {
                    if(child.material.sheenColor) child.material.sheenColor.set(value);
                    child.material.needsUpdate = true;
                }
            });

            if(me.saveTimeout) clearTimeout(me.saveTimeout);
            me.saveTimeout = setTimeout(me.save.bind(me), 500);
        });
    }
}
import Vue3DModelReader from "../3dvue/utils/Vue3DModelReader";

$body.on("click","[api-click]",function(e){

    let $clicked=$(this);
    let action=$(this).attr("api-click");
    let uid=null;
    let $article=null;
    let file=null;

    //e.preventDefault();
    e.stopPropagation();

    switch (action) {

        case "3dvue/update_mesh_material":

            let model = $clicked.attr("model-name");
            let mesh = $clicked.attr("mesh-name");
            let material = $clicked.attr("material-name");
            let value = $(this).prop('checked');

            $(".update-product-db").addClass("blink");

            if(model && mesh && material)
            {
                api.Api3dvue.getModelByName(model, (msg, html, data)=>{
                    let meshObj = JSON.parse(data.meshMaterialList);
                    if(value) meshObj[mesh].push(material);
                    else {
                        let index = meshObj[mesh].indexOf(material);
                        if(index > -1) meshObj[mesh].splice(index, 1);
                    }

                    let dataUpdate = {
                        name:data.name,
                        mesh_material_list: JSON.stringify(meshObj)
                    }

                    api.Api3dvue.updateModel(dataUpdate, null, null);
                }, null);
            }

            break;

        case "3dvue/update_model_db":

            let bt_text = $clicked.html();
            $("[api-click]").attr("disabled", "disabled");
            $clicked.attr("disabled", "disabled");

            $clicked.html("List files...");

            api.Api3dvue.updateModelDB( (msg, html, data)=>{

                let message = "<b>"+msg+"</b>";
                if(data["created"].length > 0) message += "<br>"+data["created"].length + " modèles ajoutés";
                if(data["updated"].length > 0) message += "<br>"+data["updated"].length + " modèles updatés";
                if(data["deleted"].length > 0) message += "<br>"+data["deleted"].length + " modèles supprimés";

                window.wysiwyg.notifier.notify(message, 5,"white","cq-edit");

                $clicked.html("Analysing files...");

                let dataToAnalyse = data["created"].concat(data["updated"]);
                console.log(dataToAnalyse);

                if(dataToAnalyse.length > 0)
                {
                    let modelReader = new Vue3DModelReader(dataToAnalyse);
                    modelReader.on(Vue3DModelReader.EVENT_READ_COMPLETE, ()=>{
                        window.wysiwyg.notifier.notify("<b>Analyse des modèles terminée</b>", 3,"white","cq-edit");

                        let time = 3;
                        setInterval(function(){
                            if(time === 0) location.reload();
                            else {
                                $clicked.html("Reload in " + time);
                                time--;
                            }
                        }, 1000);
                    });

                    modelReader.on(Vue3DModelReader.READ_PROGRESS, (info)=>{
                        $clicked.html("Analyse model "+info.progress);
                    });
                } else {
                    if(data["deleted"].length > 0) {
                        let time = 3;
                        setInterval(function(){
                            if(time === 0) location.reload();
                            else {
                                $clicked.html("Reload in " + time);
                                time--;
                            }
                        }, 1000);
                    } else {
                        $clicked.html(bt_text);
                        $("[api-click]").removeAttr("disabled");
                    }
                }
            }, null);

            break;

        case "3dvue/update_material_db":

            $clicked.attr("disabled", "disabled");
            $("[api-click]").attr("disabled", "disabled");

            api.Api3dvue.updateMaterialDB( (msg, html, data)=>{
                let message = "<b>"+msg+"</b>";
                if(data["created"].length > 0) message += "<br>"+data["created"].length + " materials ajoutés";
                if(data["updated"].length > 0) message += "<br>"+data["updated"].length + " materials updatés";
                if(data["deleted"].length > 0) message += "<br>"+data["deleted"].length + " materials supprimés";

                window.wysiwyg.notifier.notify(message, 5,"white","cq-edit");

                if(data["created"].length > 0 || data["deleted"].length > 0)
                {
                    let time = 3;
                    setInterval(function(){
                        if(time === 0) location.reload();
                        else {
                            $clicked.html("Reload in " + time);
                            time--;
                        }
                    }, 1000);
                } else {
                    $clicked.removeAttr("disabled");
                    $("[api-click]").removeAttr("disabled");
                }

            }, null);

            break;

        case "3dvue/update_product_db":

            let bt_text_product = $clicked.html();
            $clicked.html("Generate product");
            $clicked.attr("disabled", "disabled");
            $clicked.removeClass("blink");


            api.Api3dvue.updateProductDB( (msg, html, data)=>{
                let message = "<b>"+msg+"</b>";
                if(data["created"].length > 0) message += "<br>"+data["created"].length + " produits ajoutés";
                if(data["deleted"].length > 0) message += "<br>"+data["deleted"].length + " produits supprimés";

                window.wysiwyg.notifier.notify(message, 5,"white","cq-edit");

                if(data["created"].length > 0 || data["deleted"].length > 0)
                {
                    let time = 3;
                    setInterval(function(){
                        if(time === 0) location.reload();
                        else {
                            $clicked.html("Reload in " + time);
                            time--;
                        }
                    }, 1000);
                } else {
                    $clicked.html(bt_text_product);
                    $clicked.removeAttr("disabled");
                }
            }, null);

            break;

        default:
            alert(`api-click Action ${action} non prise en charge`);
    }
});


$body.on("submit","[api-submit]",function(e){
    let $form=$(this);
    let action=$(this).attr("api-submit");
    e.preventDefault();
    e.stopPropagation();

    switch (action) {
        case "todo":
        default:
            alert(`Action ${action} non prise en charge par api-submit`);

    }
});
import Vue3DModelReader from "../3dvue/utils/Vue3DModelReader";
import Vue3DProductGenerator from "../3dvue/utils/Vue3DProductGenerator";
import Vue3DGltfGenerator from "../3dvue/utils/Vue3DGltfGenerator";

import QRCode from 'qrcode'

$body.on("click","[api-click]",function(e){

    let $clicked=$(this);
    let $modal = $('#processModal');
    let $title = $('#process-modal-title');
    let $info = $('#process-modal-info');
    let $button = $('#process-modal-close');

    let action=$(this).attr("api-click");
    let mesh = null;
    let material = null;

    //e.preventDefault();
    e.stopPropagation();

    switch (action) {

        case "3dvue/update_bdd":
            $modal.modal('show');
            $title.html("Mise à jour de la base de données");
            $info.html("");
            $info.append("Lecture des fichiers 3D...<br><br>");

            api.Api3dvue.updateModelDB( (msg, html, data)=> {
                updateModelDBDisplay(msg, data, $info, ()=>{

                    let dataToAnalyse = data["created"].concat(data["updated"]);
                    analyseModel(dataToAnalyse, $info, ()=>{

                        api.Api3dvue.updateMaterialDB( (msg, html, data)=>{
                            updateMaterialDBDisplay(msg, data, $info, ()=>
                            {
                                $button.removeAttr("disabled");
                                $button.attr("api-click","3dvue/reload_page");
                            });
                        });
                    });
                });
            });

            break;

        case "3dvue/reload_page":
            location.reload();
            break;

        case "3dvue/update_mesh_material":

            let model = $clicked.attr("model-name");
            mesh = $clicked.attr("mesh-name");
            material = $clicked.attr("material-name");
            let value = $(this).prop('checked');

            $(".update-product-db").addClass("blink");

            if(model && mesh && material)
            {
                api.Api3dvue.getModelByName(model, (msg, html, data)=>{
                    let meshObj = JSON.parse(data.meshMaterialList);
                    if(value) meshObj[mesh].push(material);
                    else {
                        let index = meshObj[mesh].indexOf(material);
                        if(index > -1) meshObj[mesh].splice(index, 1);
                    }

                    let dataUpdate = {
                        name:data.name,
                        mesh_material_list: JSON.stringify(meshObj)
                    }

                    api.Api3dvue.updateModel(dataUpdate, null, null);
                }, null);
            }

            break;


        case "3dvue/change_material":

            mesh = $clicked.attr("mesh-name");
            material = $clicked.attr("material-name");

            let materials = {}
            materials[mesh] = material;

            window.Site.loadMaterials(materials);

            break;

        case "3dvue/download-product":

            Vue3DProductGenerator.download(window.Site.vue3d.model);

            break;

        case "3dvue/start-ar":

            let mobile = false;
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                mobile = true;
            }

            //si mobile go sur la page
            if(mobile)
            {
                window.open('http://dev.3dvue.fr/v/3dvue/utils/ar?p='+LayoutVars.productId,'_blank');
            } else {

                QRCode.toDataURL('http://dev.3dvue.fr/v/3dvue/utils/ar?p='+LayoutVars.productId)
                    .then(url => {
                        let $qrmodal = $('.qr-code-modal');
                        let $qrcode = $('.qr-code-modal .qr-code');

                        $qrcode.empty();
                        $qrcode.append('<img src="'+url+'"/>');
                        $qrmodal.addClass("show");


                        $qrmodal.click(function(){
                            $qrmodal.removeClass("show");
                        });

                    })
                    .catch(err => {
                        console.error(err)
                    });
            }
            break;

        case "3dvue/generate_gltf":

            let name = $clicked.attr("model-name");
            let file = $clicked.attr("glb-file");

            $modal.modal('show');
            $title.html("Génération du gltf");
            $info.html("");
            $info.append("Génération du gltf...<br><br>");

            let gltfGenerator = new Vue3DGltfGenerator(file);
            gltfGenerator.on(Vue3DProductGenerator.EVENT_COMPLETE, (url)=>{

                api.Api3dvue.updateModel({name:name, gltf:url}, ()=>{
                    $info.html("");
                    $info.append("Génération réussie...<br><br>");

                    $button.removeAttr("disabled");
                    $button.attr("api-click","3dvue/reload_page");
                }, null);

            });

            gltfGenerator.on(Vue3DGltfGenerator.EVENT_PROGRESS, (info)=>{
                console.log(info);
            });

            break;

        case "3dvue/screenshot":

            let base64 = window.Site.vue3d.getImageFromRenderer();

            let a = document.createElement("a"); //Create <a>
            let timestamp = +new Date;
            a.href = base64; //Image Base64 Goes here
            a.download = "3dvue-"+timestamp+".png"; //File name Here
            a.click();

            break;

        default:
            alert(`api-click Action ${action} non prise en charge`);
    }
});


$body.on("submit","[api-submit]",function(e){
    let $form=$(this);
    let action=$(this).attr("api-submit");
    e.preventDefault();
    e.stopPropagation();

    switch (action) {
        case "todo":
        default:
            alert(`Action ${action} non prise en charge par api-submit`);

    }
});


function updateModelDBDisplay(msg, data, $info, cbSuccess)
{
    $info.append(msg+"<br><br>");
    if (data["created"].length > 0) {
        $info.append(data["created"].length + " nouveaux fichiers 3D<br>");
        for(let i = 0; i<data["created"].length; i++)
        {
            $info.append("&nbsp;&nbsp;"+data["created"][i].name + "<br>");
        }
        $info.append("<br>");
    }

    if (data["updated"].length > 0) {
        $info.append(data["updated"].length + " fichiers 3D mis à jour<br>");
        for(let i = 0; i<data["updated"].length; i++)
        {
            $info.append("&nbsp;&nbsp;"+data["updated"][i].name + "<br>");
        }
        $info.append("<br>");
    }

    if (data["deleted"].length > 0) {
        $info.append(data["deleted"].length + " fichiers 3D supprimés<br>");
        for(let i = 0; i<data["deleted"].length; i++)
        {
            $info.append("&nbsp;&nbsp;"+data["deleted"][i].name + "<br>");
        }
        $info.append("<br>");
    }

    if(cbSuccess) cbSuccess();
}


function updateMaterialDBDisplay(msg, data, $info, cbSuccess)
{
    $info.append(msg+"<br><br>");
    if (data["created"].length > 0) {
        $info.append(data["created"].length + " nouveaux materiaux<br>");
        for(let i = 0; i<data["created"].length; i++)
        {
            $info.append("&nbsp;&nbsp;"+data["created"][i].name + "<br>");
        }
        $info.append("<br>");
    }

    if (data["updated"].length > 0) {
        $info.append(data["updated"].length + " materiaux mis à jour<br>");
        for(let i = 0; i<data["updated"].length; i++)
        {
            $info.append("&nbsp;&nbsp;"+data["updated"][i].name + "<br>");
        }
        $info.append("<br>");
    }

    if (data["deleted"].length > 0) {
        $info.append(data["deleted"].length + " materiaux supprimés<br>");
        for(let i = 0; i<data["deleted"].length; i++)
        {
            $info.append("&nbsp;&nbsp;"+data["deleted"][i].name + "<br>");
        }
        $info.append("<br>");
    }

    if(cbSuccess) cbSuccess();
}

function analyseModel(dataToAnalyse, $info, cbSuccess)
{
    if(dataToAnalyse <= 0) {
        if(cbSuccess) cbSuccess();
        return
    }

    $info.append("Analyse des nouveaux fichiers 3D...<br><br>");

    let $progress = $("<div></div>")
    $info.append($progress);

    let modelReader = new Vue3DModelReader(dataToAnalyse);
    modelReader.on(Vue3DModelReader.EVENT_READ_COMPLETE, ()=>{

        $progress.html("Analyse des modèles terminée<br><br>");
        if(cbSuccess) cbSuccess();
    });

    modelReader.on(Vue3DModelReader.READ_PROGRESS, (info)=>{
        $progress.html("Analyse du modèle : "+info.progress+"<br>");
    });
}
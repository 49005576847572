import * as THREE from 'three';
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader";


export default class Vue3DRenderer extends THREE.WebGLRenderer {

    vue3d = null;

    constructor(_vue3D) {
        super({antialias: true, alpha:true, stencil:true, precision:"highp"});

        this.vue3d = _vue3D;

        this.setSize( this.vue3d.domContainer.clientWidth, this.vue3d.domContainer.clientHeight );
        this.shadowMap.enabled = true;
        this.shadowMap.type = THREE.VSMShadowMap;
        this.setPixelRatio(window.devicePixelRatio);

        this.outputColorSpace = THREE.LinearSRGBColorSpace;

        const toneMappingOptions = {
            None: THREE.NoToneMapping,
            Linear: THREE.LinearToneMapping,
            Reinhard: THREE.ReinhardToneMapping,
            Cineon: THREE.CineonToneMapping,
            ACESFilmic: THREE.ACESFilmicToneMapping,
            Neutral: THREE.NeutralToneMapping
        };
        this.toneMapping = toneMappingOptions[this.vue3d.envSettings.envmap_tone_mapping];
        this.toneMappingExposure = this.vue3d.envSettings.envmap_exposure;

        this.vue3d.domContainer.appendChild( this.domElement );
        this.domElement.style.background = this.vue3d.envSettings.bg_color;
    }

    loadHDR(_cbSuccess)
    {
        let me = this;

        if(me.vue3d.envSettings.envmap)
        {
            let path = me.vue3d.httpRootPath+"/"+me.vue3d.envSettings.envmap;

            new RGBELoader().setDataType(THREE.HalfFloatType )
                .load( path, function ( texture ) {


                    texture.mapping = THREE.EquirectangularReflectionMapping;
                    texture.colorSpace = THREE.LinearSRGBColorSpace;
                    texture.magFilter = THREE.LinearFilter;
                    //me.vue3d.scene.background = texture;
                    me.vue3d.scene.environment = texture;

                    if(_cbSuccess) _cbSuccess(me.vue3d);

                }, (e)=>{
                    //if(_cbProgress) _cbProgress(_context, "initialize", Math.round(e.loaded/e.total*100));
                });
        } else {
            me.vue3d.scene.environment = null;
            if(_cbSuccess) _cbSuccess(me.vue3d);
        }


    }


    update()
    {
        this.render( this.vue3d.scene, this.vue3d.camera );
    }

    resize()
    {
        this.setSize( this.vue3d.domContainer.clientWidth, this.vue3d.domContainer.clientHeight );
    }
}
import * as THREE from 'three';
import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";

import Vue3D from "../Vue3D";

export default class Vue3DModelManager {

    constructor() {}

    static load(_vue3d, modelFile, options = null, cbSuccess = null) {

        _vue3d.domContainer.classList.add("loading");

        _vue3d.stopRenderer();

        Vue3DModelManager.removeAll(_vue3d);

        let ext = modelFile.split('.').pop();
        switch (ext)
        {
            case 'fbx':
                Vue3DModelManager.loadFBX(_vue3d, modelFile, options, cbSuccess);
                break;
            case 'glb':
                Vue3DModelManager.loadGLB(_vue3d, modelFile, options, cbSuccess);
                break;
        }
    }


    static loadFBX(_vue3d, modelFile, options = null, cbSuccess = null)
    {
        const baseMaterial = new THREE.MeshStandardMaterial( {
            color: 0xffffff
        });

        const fbxLoader = new FBXLoader();
        fbxLoader.load(_vue3d.httpRootPath+"/"+modelFile+(_vue3d.noCach?"?no-cach="+Math.random():""),
            (object) => {
                _vue3d.model = object;
                Vue3DModelManager.importModelInScene(_vue3d, options, cbSuccess);
            },
            (xhr) => {
                //me.onLoadProgress(me, "loading model", Math.round(xhr.loaded / xhr.total * 100));
            },
            (error) => {
                console.log(error);
            }
        )
    }

    static loadGLB(_vue3d, modelFile, options = null, cbSuccess = null) {
        const loader = new GLTFLoader();
        loader.load(_vue3d.httpRootPath+"/"+modelFile+(_vue3d.noCach?"?no-cach="+Math.random():""),
            (gltf) => {
                _vue3d.model = gltf.scene;
                Vue3DModelManager.importModelInScene(_vue3d, options, cbSuccess);
            },
            (xhr) => {
                //me.onLoadProgress(me, "loading model", Math.round(xhr.loaded / xhr.total * 100));
            },
            (error) => {
                console.log(error);
            }
        )
    }


    static importModelInScene(_vue3d, options = null, cbSuccess = null)
    {
        const baseMaterial = new THREE.MeshStandardMaterial({
            color: 0xffffff
        });

        _vue3d.model.traverse(function (child) {
            if (child.isMesh) {
                child.castShadow = true;
                //child.receiveShadow = true;
                child.material = baseMaterial;
            }

            Vue3DModelManager.addLabel(_vue3d, child, options);
        });

        if(options && options.materials) _vue3d.setMaterials(options.materials);
        else {
            _vue3d.domContainer.classList.remove("loading");
            _vue3d.emit(Vue3D.LOAD_COMPLETE);
        }

        _vue3d.modelRoot.add(_vue3d.model);
        _vue3d.aoShadow.render();

        _vue3d.startRenderer();

        _vue3d.controls.resetCameraToObject(_vue3d.model, (options && options.animate != null)?options.animate:true);

        if(cbSuccess) cbSuccess();
    }

    static removeAll(_vue3d)
    {
        if(!_vue3d.modelRoot)
        {
            _vue3d.modelRoot = new THREE.Group();
            _vue3d.scene.add( _vue3d.modelRoot );
        }

        while ( _vue3d.modelRoot.children.length > 0 ) {

            const object = _vue3d.modelRoot.children[ 0 ];
            object.parent.remove( object );
        }
    }

    static addLabel(_vue3d, child, options)
    {
        if(options && options.labels)
        {
            if(child.isGroup && child.name.includes("label"))
            {
                if(_vue3d.labelRenderer && options.labels) {
                    for (const [key, value] of Object.entries(options.labels)) {
                        if(child.name.includes(key)){
                            _vue3d.labelRenderer.addLabel(_vue3d.modelRoot, child.position, value);
                            break;
                        }
                    }

                }
            }
        }
    }
}
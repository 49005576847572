import * as THREE from 'three';

export default class Vue3DCaptureMode {

    constructor() {}

    static set(_vue3d) {

        _vue3d.controls.enabled = false;
        _vue3d.renderer.preserveDrawingBuffer = true;

        _vue3d.camera.position.x = _vue3d.camera.position.y = _vue3d.camera.position.z = 0;
        _vue3d.camera.rotation.x = _vue3d.camera.rotation.y = _vue3d.camera.rotation.z = 0;
        _vue3d.controls.target.set( 0, 0, 0 );

        _vue3d.camera.position.y = 93;

        _vue3d.domContainer.classList.add("loading");

        if(!_vue3d.modelRoot)
        {
            _vue3d.modelRoot = new THREE.Group();
            _vue3d.scene.add( _vue3d.modelRoot );
        }

        //me.model = new THREE.Mesh(new THREE.PlaneGeometry(50, 50));
        _vue3d.model = new THREE.Mesh(new THREE.SphereGeometry(23, 128, 64));
        _vue3d.model.rotation.x = -Math.PI/2;

        _vue3d.model.traverse(function (child) {
            if (child.isMesh) {
                child.name = "preview";
                child.castShadow = true;
            }
        });

        _vue3d.modelRoot.add(_vue3d.model);
    }
}
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min'

import {EventEmitter} from "events";
import * as THREE from 'three';
import Vue3DConfiguratorHDR from "./Vue3DConfiguratorHDR";
import Vue3DConfiguratorShadow from "./Vue3DConfiguratorShadow";
import Vue3DConfiguratorModel from "./Vue3DConfiguratorModel";
import Vue3DConfiguratorMaterial from './Vue3DConfiguratorMaterial';
import Vue3D from "../Vue3D";
import Stats from "stats.js";

export default class Vue3DConfigurator extends EventEmitter {

    vue3d = null;
    gui = null;
    stats = null;

    api = {
        stats : {
            display: false
        },
        project : {
            forceCache:true
        }
    }

    modelConfigurator = null;
    materialConfigurator = null;

    constructor(_vue3d) {
        super();

        let me = this;
        me.vue3d = _vue3d;
        me.gui = new GUI();

        me.addProject();
        me.addStats();

        let hdrConfigurator = new Vue3DConfiguratorHDR(this);
        hdrConfigurator.on(Vue3D.CONFIGURATOR_UPDATE_CONFIG, (data)=>{
            me.emit(Vue3D.CONFIGURATOR_UPDATE_CONFIG, data);
        });
        let shadowConfigurator = new Vue3DConfiguratorShadow(this);
        shadowConfigurator.on(Vue3D.CONFIGURATOR_UPDATE_CONFIG, (data)=>{
            me.emit(Vue3D.CONFIGURATOR_UPDATE_CONFIG, data);
        });
        me.modelConfigurator = new Vue3DConfiguratorModel(this);
        me.materialConfigurator = new Vue3DConfiguratorMaterial(this);
        me.materialConfigurator.on(Vue3D.CONFIGURATOR_UPDATE_MATERIAL, (data)=>{
            me.emit(Vue3D.CONFIGURATOR_UPDATE_MATERIAL, data);
        });
    }

    addProject()
    {
        let me = this;

        me.api.project.forceCache = me.vue3d.noCach;

        const folder = me.gui.addFolder( 'Project');
        folder.add(me.api.project, 'forceCache').onChange(function (value) {
            let data = {
                force_cach:value
            }
            me.vue3d.noCach = value;

            me.emit(Vue3D.CONFIGURATOR_UPDATE_CONFIG, data);
        });
    }

    addStats()
    {
        let me = this;

        me.stats = new Stats();
        me.stats.showPanel( 0 ); // 0: fps, 1: ms, 2: mb, 3+: custom

        const folder = me.gui.addFolder( 'Stats');
        folder.add(me.api.stats, 'display').onChange(function (display) {
            if(display) document.body.appendChild( me.stats.dom );
            else document.body.removeChild( me.stats.dom );
        });
    }
}
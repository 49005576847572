import Vue3D from "./../Vue3D";

export default class Vue3DConfiguratorWrapper
{

    static init(site, container)
    {
        site.vue3d = new Vue3D(container);
        site.vue3d.initScene();
        site.vue3d.initConfigurator();
        site.vue3d.on(Vue3D.CONFIGURATOR_UPDATE_MATERIAL, (data)=>{
            if(LayoutVars.isAdmin) api.Api3dvue.updateMaterial(data, null, null);
        });
        site.vue3d.on(Vue3D.CONFIGURATOR_UPDATE_CONFIG, (data)=>{
            if(LayoutVars.isAdmin) api.Api3dvue.updateConfig(data, null, null);
        });


    }
}
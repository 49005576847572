import Vue3D from "./../Vue3D";
import {GLTFExporter} from "three/examples/jsm/exporters/GLTFExporter";

export default class Vue3DProductGeneratorWrapper
{

    static init(site, container)
    {
        site.vue3d = new Vue3D(container);
        site.vue3d.initScene();

        Vue3DProductGeneratorWrapper.exportProductListener(site);

        Vue3DProductGeneratorWrapper.loadProduct(site);
    }

    static loadProduct(site)
    {
        let $products = $("[product]");

        let name = $products.first().attr("model");
        let options = {};
        options.materials = JSON.parse($products.first().attr("options"));
        //.remove();

        site.loadModel(name, options);
    }

    static exportProductListener(site)
    {
        site.vue3d.on(Vue3D.LOAD_COMPLETE, ()=>{

            let $products = $("[product]");
            let outputname = $products.first().attr("outputname");

            const exporter = new GLTFExporter();

            exporter.parse(
                site.vue3d.model,
                // called when the gltf has been generated
                function ( gltf ) {

                    gltf["materials"].forEach(material=>{
                        if (material.pbrMetallicRoughness && material.pbrMetallicRoughness.metallicRoughnessTexture) {
                            delete material.pbrMetallicRoughness.metallicRoughnessTexture.channel;
                        }
                    });

                    api.Api3dvue.saveExport(outputname+".gltf", JSON.stringify(gltf, null, 2), null, null);

                    if($products.length > 1)
                    {
                        $products.first().remove();
                        Vue3DProductGeneratorWrapper.loadProduct(site);
                    } else {
                        $products.first().remove();
                    }
                },
                // called when there is an error in the generation
                function ( error ) {
                    console.log( 'An error happened', error );
                }, {
                    trs:true,
                    maxTextureSize:512
                }
            );
        });
    }
}
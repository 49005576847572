import {EventEmitter} from "events";
import * as THREE from 'three';
import Vue3D from "../Vue3D";

export default class Vue3DConfiguratorHDR extends EventEmitter {

    vue3d = null;
    folder = null;

    saveTimeout = null;

    api = {
        display:false,
        map:"none",
        exposure:1,
        toneMapping:"None",
        backgroundColor:"#ebebeb"
    }

    constructor(_configurator)
    {
        super();

        let me = this;
        me.vue3d = _configurator.vue3d;

        me.folder = _configurator.gui.addFolder( 'Environment');

        me.display();
        me.selector();
        me.exposure();
        me.toneMapping();
        me.backgroundColor();

        me.folder.close();
    }


    display()
    {
        let me = this;

        me.folder.add(me.api, 'display').onChange(function (display) {
            if(display) me.vue3d.scene.background = me.vue3d.scene.environment;
            else me.vue3d.scene.background = null;
        });
    }

    selector()
    {
        let me = this;
        let envmapFile = me.vue3d.envSettings.envmap.split(/[\\/]/).pop();
        let baseName = envmapFile.substring(0, envmapFile.lastIndexOf("."));
        if(!me.vue3d.envSettings.envmap) me.api.map = "none";
        else me.api.map = baseName;
        console.log(me.vue3d.envSettings.envmap);

        me.folder.add( me.api, 'map' ).options( LayoutVars.envmaps.map(a => a.name) ).onChange(function (selection) {
            const mapInfo = LayoutVars.envmaps.find(({ name }) => name === selection);
            me.vue3d.envSettings.envmap = mapInfo.path;
            me.vue3d.renderer.loadHDR(function () {
                if(me.vue3d.scene.background != null ) me.vue3d.scene.background = me.vue3d.scene.environment;
            });

            if(me.saveTimeout) clearTimeout(me.saveTimeout);
            me.saveTimeout = setTimeout(me.save.bind(me), 500);
        });
    }

    exposure()
    {
        let me = this;
        me.api.exposure = parseInt(me.vue3d.renderer.toneMappingExposure);
        me.folder.add(me.api, 'exposure', 0, 2, 0.01).onChange((value)=>{
            me.vue3d.renderer.toneMappingExposure = value;

            if(me.saveTimeout) clearTimeout(me.saveTimeout);
            me.saveTimeout = setTimeout(me.save.bind(me), 500);
        });
    }

    toneMapping()
    {
        let me = this;

        const toneMappingOptions = {
            None: THREE.NoToneMapping,
            Linear: THREE.LinearToneMapping,
            Reinhard: THREE.ReinhardToneMapping,
            Cineon: THREE.CineonToneMapping,
            ACESFilmic: THREE.ACESFilmicToneMapping,
            Neutral: THREE.NeutralToneMapping
        };

        me.api.toneMapping = Object.keys(toneMappingOptions).find(key => toneMappingOptions[key] === me.vue3d.renderer.toneMapping);
        me.folder.add(me.api, "toneMapping").options( Object.keys(toneMappingOptions) ).onChange((value)=>{
            me.vue3d.renderer.toneMapping = toneMappingOptions[value];

            if(me.saveTimeout) clearTimeout(me.saveTimeout);
            me.saveTimeout = setTimeout(me.save.bind(me), 500);
        });
    }

    backgroundColor()
    {
        let me = this;

        me.api.backgroundColor = me.vue3d.renderer.domElement.style.background;
        me.folder.addColor(me.api, 'backgroundColor').onChange((value)=>{
            let color = new THREE.Color(value);
            me.vue3d.renderer.domElement.style.background = "#"+color.getHexString();

            if(me.saveTimeout) clearTimeout(me.saveTimeout);
            me.saveTimeout = setTimeout(me.save.bind(me), 500);
        });
    }

    save()
    {
        let me = this;

        const mapInfo = LayoutVars.envmaps.find(({ name }) => name === me.api.map);
        if(mapInfo.name === "none")
        {
            mapInfo.path = null;
            mapInfo.size = 0;
        }

        let color = new THREE.Color(me.api.backgroundColor);
        let data = {
            envmap:mapInfo.path,
            envmap_exposure:me.api.exposure,
            envmap_tone_mapping:me.api.toneMapping,
            bg_color:"#"+color.getHexString()
        }

        me.emit(Vue3D.CONFIGURATOR_UPDATE_CONFIG, data);
    }

}
import {FBXLoader} from "three/examples/jsm/loaders/FBXLoader";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
var EventEmitter = require('events').EventEmitter;

export default class Vue3DModelReader extends EventEmitter
{

    static EVENT_READ_COMPLETE = "EVENT_READ_COMPLETE";
    static READ_PROGRESS = "READ_PROGRESS";

    constructor(_data) {

        super();

        let me = this;

        me.data = _data;
        me.count = me.data.length;
        me.loadNextModel();
    }

    loadNextModel()
    {
        let me = this;
        let httpRootPath = JSON.parse(LayoutVars.envSettings).root_path;

        if(me.data.length > 0) {

            let ext = me.data[0].path.split('.').pop();

            switch (ext)
            {
                case 'fbx':
                    const fbxLoader = new FBXLoader();
                    fbxLoader.load(httpRootPath+"/"+me.data[0].path+"?no-cach="+Math.random(),
                        (object) => {
                            me.analyseModel(object, me.data[0], () => {
                                me.data.shift();
                                me.emit(Vue3DModelReader.READ_PROGRESS, {progress:me.count-me.data.length+"/"+me.count});
                                me.loadNextModel();
                            });
                        });
                    break;
                case 'glb':
                    const loader = new GLTFLoader();
                    loader.load(httpRootPath+"/"+me.data[0].path+"?no-cach="+Math.random(),
                        (gltf) => {
                            me.analyseModel(gltf.scene, me.data[0], ()=>{
                                me.data.shift();
                                me.emit(Vue3DModelReader.READ_PROGRESS, {progress:me.count-me.data.length+"/"+me.count});
                                me.loadNextModel();
                            });
                        });
                    break;
            }

        }
        else {
            me.emit(Vue3DModelReader.EVENT_READ_COMPLETE);
        }
    }


    analyseModel(object, info, cbSuccess)
    {
        let mesh = [];
        //on liste tous les meshs qui portent le bon pattern
        object.traverse(function (child) {
            if (child.isMesh) {
                let pattern = "mesh_";
                if(!child.name.includes(pattern)) return;
                let identifier = pattern+child.name.slice(pattern.length, pattern.length+3);

                if(mesh.includes(identifier)) return;
                mesh.push(identifier);
            }
        });



        let update = false;
        let config = {};

        //si la liste n'exsite pas on la crée avec la liste du modèle 3D
        if(info.mesh_material_list === null || info.mesh_material_list.length <= 0)
        {
            update = true;
            mesh.forEach((m)=>{
                config[m] = [];
            });
        } else {
            //on recupère la liste de la BDD
            config = JSON.parse(info.mesh_material_list);

            //si le mesh n'existe pas dans la BDD on l'ajoute
            mesh.forEach((m)=>{
                if(!config.hasOwnProperty(m))
                {
                    config[m] = [];
                    update = true;
                }
            });
            //si le mesh existe dans la BDD mais pas de le modèle on le supprime
            for(let [key, val] of Object.entries(config))
            {
                if(!mesh.includes(key))
                {
                    delete config[key];
                    update = true;
                }
            }
        }

        //on envoi le resultat à la BDD et on passe au suivant
        if(update)
        {
            const ordered = Object.keys(config).sort().reduce(
                (obj, key) => {
                    obj[key] = config[key];
                    return obj;
                },
                {}
            );

            let data = {
                name:info.name,
                mesh_material_list: JSON.stringify(ordered)
            }

            api.Api3dvue.updateModel(data, (e)=>{
                if(cbSuccess) cbSuccess();
            }, null);
        } else {
            if(cbSuccess) cbSuccess();

        }
    }
}
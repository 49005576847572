import Vue3D from "./../Vue3D";

export default class Vue3DThumbnailGeneratorWrapper
{

    static generateMaterialThumbnail(site, container)
    {
        site.vue3d = new Vue3D(container);
        site.vue3d.initScene();
        site.vue3d.initPreviewMode();

        let materials = JSON.parse($(container).attr("material-list"));
        let curMaterial = 0;

        site.vue3d.on(Vue3D.LOAD_COMPLETE, ()=>{

            setTimeout(()=>{
                let base64 = site.vue3d.getImageFromRenderer();
                /*
                var image = $("<img>", {
                    "src": base64
                });

                $('body').append(image);
                */
                api.Api3dvue.saveBase64asThumbnailMaterial(materials[curMaterial], base64, null, null);

                curMaterial++;
                if(curMaterial < materials.length) loadMaterial();

            }, 50);


        });

        let loadMaterial = function()
        {
            let options = {materials:{"preview":materials[curMaterial]}};
            site.loadMaterials(options.materials);
        }

        loadMaterial();
    }


    static generateModelThumbnail(site, container)
    {
        site.vue3d = new Vue3D(container);
        site.vue3d.initScene();

        let models = JSON.parse($(container).attr("model-list"));
        let curModel = 0;

        let options = {animate:false};

        site.vue3d.on(Vue3D.LOAD_COMPLETE, ()=>{

            setTimeout(()=>{
                let base64 = site.vue3d.getImageFromRenderer();
                api.Api3dvue.saveBase64asThumbnailModel(models[curModel], base64, null, null);

                curModel++;
                if(curModel < models.length) site.loadModel(models[curModel], options);

            }, 50);
        });

        site.loadModel(models[curModel], options);
    }
}
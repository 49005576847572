
import {CSS2DRenderer, CSS2DObject} from "three/examples/jsm/renderers/CSS2DRenderer";

export default class Vue3DLabelRenderer extends CSS2DRenderer {

    constructor(_vue3d) {
        super();

        this.setSize( _vue3d.domContainer.clientWidth, _vue3d.domContainer.clientHeight );
        this.domElement.style.position = 'absolute';
        this.domElement.style.top = '0px';
        this.domElement.style.pointerEvents = 'none';
        _vue3d.domContainer.appendChild( this.domElement );
    }

    addLabel(parent, position, text) {
        const div = document.createElement( 'div' );
        div.classList.add("label");
        div.innerHTML  = text;

        const label = new CSS2DObject( div );
        label.position.copy( position );
        parent.add(label);
    }
}
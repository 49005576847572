export default class Vue3DConfiguratorModel {

    vue3d = null;
    folder = null;
    urlCtrl = null;

    api = {
        model:"",
        url:""
    }


    constructor(_configurator)
    {
        let me = this;
        me.vue3d = _configurator.vue3d;

        me.folder = _configurator.gui.addFolder( 'Model');

        this.urlCtrl = me.folder.add(me.api, 'url');

        me.folder.add( me.api, 'model' ).options( LayoutVars.models.map(a => a.name) ).onChange(function (selection) {

            me.urlCtrl.setValue(me.vue3d.envSettings.iframe_url+"?model="+selection);

            _configurator.materialConfigurator.destroy();

            const modelInfo = LayoutVars.models.find(({ name }) => name === selection);
            me.vue3d.loadModel(modelInfo.path, null, _configurator.materialConfigurator.listMesh.bind(_configurator.materialConfigurator));
        });
    }

    updateURLFromScene()
    {
        let me = this;

        let mesh = [];
        me.vue3d.model.traverse(function (child) {
            if (child.isMesh) {
                let pattern = "mesh_";
                if(!child.name.includes(pattern)) return;
                let identifier = pattern+child.name.slice(pattern.length, pattern.length+3);

                if(mesh.some(obj => obj["id"] === identifier)) return;
                mesh.push({id:identifier, mesh:child});
            }
        });

        let options = {materials:{}};
        mesh.forEach((el)=>{
            if(el.mesh.material.name !== "")
            {
                options.materials[el.id] = el.mesh.material.name;
            }
        });

        me.urlCtrl.setValue(me.vue3d.envSettings.iframe_url+"?model="+me.api.model+"&options="+JSON.stringify(options));
    }

}
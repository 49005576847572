import * as THREE from 'three';
import {RGBELoader} from "three/examples/jsm/loaders/RGBELoader";


export default class Vue3DRenderer extends THREE.WebGLRenderer {

    vue3d = null;

    constructor(_vue3D) {
        super({antialias: true, alpha:true, stencil:true, precision:"highp"});

        this.vue3d = _vue3D;

        this.setSize( this.vue3d.domContainer.clientWidth, this.vue3d.domContainer.clientHeight );
        this.shadowMap.enabled = true;
        this.shadowMap.type = THREE.VSMShadowMap;
        this.setPixelRatio(window.devicePixelRatio);

        this.outputColorSpace = THREE.SRGBColorSpace;

        const toneMappingOptions = {
            None: THREE.NoToneMapping,
            Linear: THREE.LinearToneMapping,
            Reinhard: THREE.ReinhardToneMapping,
            Cineon: THREE.CineonToneMapping,
            ACESFilmic: THREE.ACESFilmicToneMapping
        };
        this.toneMapping = toneMappingOptions[this.vue3d.envSettings.envmap_tone_mapping];
        this.toneMappingExposure = this.vue3d.envSettings.envmap_exposure;

        this.vue3d.domContainer.appendChild( this.domElement );
        this.domElement.style.background = this.vue3d.envSettings.bg_color;
    }

    loadHDR(path, _cbSuccess)
    {
        let me = this;

        new RGBELoader()
            .load( path, function ( texture ) {

                texture.mapping = THREE.EquirectangularReflectionMapping;
                texture.colorSpace = THREE.SRGBColorSpace;
                texture.magFilter = THREE.LinearFilter;
                //_scene.background = texture;
                me.vue3d.scene.environment = texture;

                if(_cbSuccess) _cbSuccess(me.vue3d);

            }, (e)=>{
                //if(_cbProgress) _cbProgress(_context, "initialize", Math.round(e.loaded/e.total*100));
            });
    }


    update()
    {
        this.render( this.vue3d.scene, this.vue3d.camera );
    }

    resize()
    {
        this.setSize( this.vue3d.domContainer.clientWidth, this.vue3d.domContainer.clientHeight );
    }
}